import assets from '~/config/assets'
import constants from '~/config/constants'

export default defineNuxtPlugin({
    name: 'globals',
    setup(nuxtApp) {
        const {
            $config: {
                public: { variant, isProduction },
            },
        } = nuxtApp

        const openDiscount = (payload: {
            id: number
            slug: string
            type: 'offer' | 'coupon'
            storeSlug?: string
            landing?: string
        }) => {
            const originResolved = isProduction ? variant.metaData.siteOrigin : location.origin

            const landingResolved = payload.landing || variant.lang.routes.brands

            const maskRoute = payload.type === 'coupon' ? 'open' : 'go'

            if (payload.storeSlug) {
                window.open(
                    `${originResolved}${variant.isCupohosting ? '' : `/${landingResolved}`}/${payload.storeSlug}?id=${payload.id}&type=${payload.type}`,
                    '_blank',
                )
                location.href = `${originResolved}/${maskRoute}/${payload.id}`
            } else {
                window.open(`${originResolved}/${maskRoute}/${payload.id}`, '_blank')
            }
        }

        return {
            provide: {
                ...variant,
                assets: assets(variant.value),
                constants,
                openers: {
                    openDiscount,
                },
            },
        }
    },
})
