import { defineStore } from 'pinia'
import type { NotificationOptions } from '~/components/header/HeaderNotifications.vue'
import type { DropdownOptionType } from '~/components/dropdowns/Options.vue'
import type { Models } from '~/types/models'
import type { Api } from '~~/global'

type GenericModal =
    | {
          type: 'confirm'
          info: {
              text: string
              continue: {
                  handler: () => any
                  text: string
              }
              cancel: {
                  handler: () => any
                  text: string
              }
          }
      }
    | {
          type: 'message'
          info: {
              refresh: (id: null | number) => void
          }
      }

export const useRootStore = defineStore('RootStore', () => {
    const layoutData = ref(null) as Ref<null | Api.Responses.General>
    const headbandData = ref(null) as Ref<null | Api.Responses.SiteHeadband>
    const siteNotification = ref(null) as Ref<null | NotificationOptions>
    const discountModal = ref(null) as Ref<null | Models.Discount>
    const siteDropdown = ref(null) as Ref<null | DropdownOptionType[]>
    const genericModal = ref(null) as Ref<null | GenericModal>

    const setGenericModal = (modal: GenericModal | null) => {
        genericModal.value = modal
    }

    const getNotification = computed(() => siteNotification)

    const setLayoutData = (data: Api.Responses.General) => {
        layoutData.value = data
    }
    const setDiscountModal = (data: Models.Discount | null) => {
        discountModal.value = data
    }
    const setHeadbandData = (data: Api.Responses.SiteHeadband) => {
        headbandData.value = data
    }
    const setSiteNotification = (data: NotificationOptions | null) => {
        if (data && data.text.length > 5) {
            siteNotification.value = data
        } else {
            siteNotification.value = null
        }
    }
    const setSiteDropdown = (data: DropdownOptionType[] | null) => {
        siteDropdown.value = data
    }

    return {
        layoutData,
        siteNotification,
        headbandData,
        getNotification,
        discountModal,
        genericModal,
        siteDropdown: computed(() => siteDropdown),
        setGenericModal,
        setLayoutData,
        setSiteDropdown,
        setHeadbandData,
        setSiteNotification,
        setDiscountModal,
    }
})
