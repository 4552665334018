export default {
    privacyPolicy: {
        path: '/v1/pages/privacy-policy',
    },
    aboutUs: {
        path: '/v1/pages/about-us',
    },
    contactUs: {
        path: '/v1/pages/contact-us',
    },
    cookies: {
        path: '/v1/pages/cookies',
    },
    legalWarning: {
        path: '/v1/pages/legal-warning',
    },
    press: {
        path: '/v1/pages/press',
    },
    job: {
        path: '/v1/pages/job',
    },
    faq: {
        path: '/v1/pages/faq',
    },
    help: {
        path: '/v1/pages/help',
    },
    communityRules: {
        path: '/v1/pages/community-rules',
    },
    contact: {
        path: '/v1/pages/contact',
    },
    sendCoupon: {
        path: '/v1/discount-code-request',
    },
}
